import { Box, useTheme } from '@mui/material';
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from 'components';
import { dateFormatIsoLocale } from 'helpers';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiltersContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { useErrorHandler } from 'react-error-boundary';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const IrrigationChart = ({ orchardSelected, ccSelected, quarterSelected }) => {
  const chartRef = useRef(null);

  const axiosContext = useContext(AxiosContext);

  const actualYear = new Date().getFullYear();

  const errorHandler = useErrorHandler();

  const theme = useTheme();

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { userClient } = useContext(Context);

  // const [tableDataState, setTableDataState] = useState(null);

  // const [isBusyState, setIsBusyState] = useState(false);

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    isLoading,
    idUnitProductive,
  } = filterState;

  const minGraphDate = `${actualYear}-01-02`;
  const maxGraphDate = `${actualYear}-01-19`;

  // definir start date evaluando si el día de hoy es mayor a la fecha mínima
  const startColdDate = new Date(minGraphDate);
  // definir end date evaluando si el día de hoy es mayor a la fecha máxima
  const endColdDate = new Date(maxGraphDate);

  const [loadingState, setLoadingState] = useState(false);
  const [graphDataState, setGraphDataState] = useState(null);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [minDateState, setminDateState] = useState(null);

  // const sampleData = {
  //   // dates: [
  //   //   '21 feb',
  //   //   '22 feb',
  //   //   '23 feb',
  //   //   '24 feb',
  //   //   '25 feb',
  //   //   '26 feb',
  //   //   '27 feb',
  //   //   '28 feb',
  //   //   '01 mar',
  //   //   '02 mar',
  //   //   '03 mar',
  //   //   '04 mar',
  //   //   '05 mar',
  //   //   '06 mar',
  //   // ],
  //   begingDate: '2025-01-01',
  //   interval: 60,
  //   moisture30cm: [
  //     75, 78, 80, 75, 73, 70, 68, 65, 63, 60, 58, 55, 53, 50, 78, 80, 75, 73,
  //     70, 68, 65, 63, 60, 78, 80, 75, 73, 70, 68, 65, 63, 60, 70, 65, 62, 60,
  //     55, 52, 50, 48, 45, 42, 40, 78, 80, 75, 73, 70, 68, 65, 63, 60, 78, 80,
  //     75, 73, 70,
  //   ],
  //   moisture60cm: [
  //     72, 74, 75, 72, 68, 65, 62, 58, 55, 52, 50, 48, 45, 43, 78, 80, 75, 73,
  //     70, 68, 65, 63, 67, 78, 80, 75, 73, 70, 68, 65, 63, 67, 70, 65, 62, 67,
  //     55, 52, 50, 48, 45, 42, 40, 78, 80, 75, 73, 70, 68, 65, 63, 67, 78, 80,
  //     75, 73, 70, 65, 63, 67, 78, 80, 75, 73, 70, 68, 65, 63, 60, 70, 65, 62,
  //     60, 55, 52, 50, 48, 45, 42, 40, 78, 80, 75, 73, 70, 68, 65, 63, 60, 78,
  //     80, 75, 73, 70,
  //   ],
  //   moisture90cm: [
  //     70, 71, 72, 70, 65, 62, 60, 55, 52, 50, 48, 45, 42, 40, 78, 80, 75, 73,
  //     70, 68, 65, 63, 60, 78, 90, 75, 73, 70, 68, 65, 63, 60, 70, 65, 62, 60,
  //     55, 52, 50, 48, 45, 42, 40, 78, 90, 75, 73, 70, 68, 65, 63, 60, 78, 90,
  //     75, 73, 70,
  //   ],
  //   irrigation: [0, 0, 10, 40],
  //   precipitation: [0, 0, 50, 25],
  // };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.get('v1/irrigation/chart', {
        params: dataQuery,
      });

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        console.log(data, '------LLEGA data');

        setGraphDataState(data);
      }
    } catch (err) {
      setGraphDataState([]);

      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = values.since;
    const dateEnd = values.until;

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      clientValue: userClient.value,
      specieValue: specie_filter,
      orchardValue: orchard_filter,
      ccValue: ccSelected,
      beginDate: dateFormatIsoLocale(dateBegin),
      untilDate: dateFormatIsoLocale(dateEnd),
    };

    console.log(data, '<--------data');

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    // setSinceSelectedState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    // setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  let optionsChart = {};

  useEffect(() => {
    // handleSubmit(onSubmit)();
    // setGraphOptionsState(sampleData);

    if (graphDataState) {
      optionsChart = {
        chart: {
          type: 'spline',
          zoomType: 'x',
          height: 500,
        },
        title: {
          text: '',
        },
        xAxis: {
          labels: {
            format: '{value:%d-%m %H:%M}', // Format DD-MM
            overflow: 'justify',
          },
          tickInterval: 1 * 60 * 60 * 1000, // 24 hours interval
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%d-%m',
            week: '%d-%m',
            month: '%d-%m',
            hour: '%H:%M',
          },
        },
        yAxis: [
          {
            title: {
              text: 'Humedad (%)',
            },
            min: 0,
            // max: 100,
            plotBands: [
              {
                from: 70,
                to: Number.MAX_VALUE,
                color: 'rgba(200, 255, 200, 0.3)',
                label: {
                  text: 'Alta Humedad',
                  style: { color: '#606060' },
                },
              },
              {
                from: 30,
                to: 70,
                color: 'rgba(255, 255, 200, 0.3)',
                label: {
                  text: 'Capacidad de Campo',
                  style: { color: '#606060' },
                },
              },
              {
                from: 0,
                to: 30,
                color: 'rgba(255, 200, 200, 0.3)',
                label: {
                  text: 'Déficit de Agua',
                  style: { color: '#606060' },
                },
              },
            ],
          },
          {
            title: {
              text: 'Riego/Precipitación (mm)',
            },
            opposite: true,
            // max: 100,
          },
        ],
        plotOptions: {
          spline: {
            pointInterval: graphDataState?.interval * 60000 || 1.8e6, // one hour (60min * 60sec * 1000ms)
            pointStart: graphDataState
              ? Date.parse(graphDataState.dateBegin)
              : Date.UTC(2022, 5, 13, 0, 0, 0),
          },
          column: {
            // pointPadding: 10,
            borderWidth: 0,
            pointInterval: 24 * 60 * 60 * 1000, // 24 hours (24h * 60min * 60sec * 1000ms)
            pointStart: graphDataState
              ? Date.parse(graphDataState.dateBegin)
              : Date.UTC(2022, 5, 13, 0, 0, 0),
            grouping: true, // Enable grouping
            groupPadding: 0.1, // Small gap between days
            pointPadding: 0, // Gap between columns in same day
            pointRange: 24 * 60 * 60 * 1000,
          },
        },
        series: [
          {
            name: '30cm',
            data: graphDataState?.moisture30cm || [],
            color: '#2f7d32',
          },
          {
            name: '60cm',
            data: graphDataState?.moisture60cm || [],
            color: '#c62828',
          },
          {
            name: '90cm',
            data: graphDataState?.moisture90cm || [],
            color: '#1565c0',
          },
          {
            name: 'Estimado a Regar mm',
            type: 'column',
            yAxis: 1,
            data: graphDataState?.irrigation || [],
            color: '#81c784',
            opacity: 0.9,
            pointInterval: 24 * 60 * 60 * 1000, // Override for this series specifically
          },
          {
            name: 'Total Regado mm',
            type: 'column',
            yAxis: 1,
            data: graphDataState?.precipitation || [],
            color: '#64b5f6',
            opacity: 0.9,
            pointInterval: 24 * 60 * 60 * 1000, // Override for this series specifically
          },
        ],
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'horizontal',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          valueDecimals: 1, // This will format all numeric values to 1 decimal place
        },
      };

      setGraphOptionsState(optionsChart);

      console.log(optionsChart, '------optionsChart');
    }
  }, [graphDataState]);

  return (
    <>
      <HighchartsWrapper
        fullScreenHandler={openFullscreen}
        title='Balance Hídrico'
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: {
                sm: '100%',
                md: '70%',
              },
              display: 'flex',
              flexDirection: 'column', // Add this
              alignItems: 'center', // Add this to keep centered
            }}
          >
            <form
              className='form-inline'
              autoComplete='on'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='input-group ml-md-2'>
                <TextFieldWidgetDateController2
                  name='since'
                  labeltext='Desde'
                  placeholder='Desde'
                  display='vertical'
                  variant='outlined'
                  format='dd-MM-yyyy'
                  style={{}}
                  minheight={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  sx={{
                    width: '150px',
                  }}
                  // value={sinceSelectedState}
                  // onChange={date => setSinceSelectedState(date)}
                  minDate={new Date(minGraphDate)}
                  maxDate={new Date(maxGraphDate)}
                  control={control}
                  errors={errors.since}
                  defaultValue={startColdDate}
                />
              </div>
              <div className='input-group ml-md-2'>
                <TextFieldWidgetDateController2
                  name='until'
                  labeltext='Hasta'
                  placeholder='Hasta'
                  display='vertical'
                  variant='outlined'
                  format='dd-MM-yyyy'
                  style={{}}
                  minheight={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  sx={{
                    width: '150px',
                  }}
                  // value={sinceSelectedState}
                  // onChange={date => setSinceSelectedState(date)}
                  minDate={minDateState}
                  maxDate={new Date(maxGraphDate)}
                  control={control}
                  errors={errors.until}
                  defaultValue={endColdDate}
                />
              </div>
              <SaveAction
                title='Filtrar'
                color='secondary'
                size='medium'
                className='roundedButton ml-md-2'
                variant='contained'
                disabled={ccSelected !== null ? false : true}
                messageType='add'
                mutationLoading={false}
                success={false}
              />
            </form>
            {errors.incorrectDates && (
              <p
                style={{
                  color: theme.palette.error.main,
                  flexGrow: 1,
                  fontSize: 12,
                  margin: '10px 0 0px',
                }}
              >
                {errors.incorrectDates.message}
              </p>
            )}
          </Box>
        </div>
        {loadingState && <LoadingWidget />}
        {!loadingState && graphOptionsState && (
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOptionsState}
            ref={chartRef}
          />
        )}
      </HighchartsWrapper>
    </>
  );
};

export default IrrigationChart;
