import React, { memo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

/**
 * El componente ColumnChart renderiza un gráfico de columnas personalizable usando Highcharts.
 *
 * @component
 * @param {Object} props - El objeto de propiedades.
 * @param {string} [props.title=''] - El título del gráfico.
 * @param {string} [props.subtitle=''] - El subtítulo del gráfico.
 * @param {Array} [props.data=[]] - Los datos a mostrar en el gráfico.
 * @param {Array} [props.colors=[]] - Los colores de las columnas.
 * @param {string} [props.type='column'] - El tipo de gráfico.
 * @param {string} [props.stacking=''] - La opción de apilamiento para las columnas.
 * @param {string} [props.tooltipFormat='{series.name}: {point.y}'] - El formato del tooltip.
 * @param {boolean} [props.exportOptions=true] - Si se muestran las opciones de exportación.
 * @param {boolean} [props.enableDataLabels=true] - Si se muestran las etiquetas de datos en las columnas.
 * @param {boolean} [props.showLegend=true] - Si se muestra la leyenda.
 * @param {string} [props.height=''] - La altura del gráfico.
 * @param {Array} [props.spacing=[0, 0, 0, 0]] - El espaciado entre los bordes del gráfico y el contenido.
 * @param {boolean} [props.shadow=false] - Si se muestra sombra en las columnas.
 * @param {number} [props.borderRadius=0] - El radio del borde de las columnas.
 * @param {number} [props.borderWidth=0] - El ancho del borde de las columnas.
 * @param {string} [props.yAxisTitle=''] - El título del eje Y.
 * @param {string} [props.xAxisCategories=''] - Las categorías del eje X.
 * @param {number} [props.gridLineWidth=0] - El ancho de las líneas de la cuadrícula.
 * @param {string} [props.valueSuffix=''] - El sufijo para los valores.
 *
 * @returns {JSX.Element} El componente de gráfico de columnas renderizado.
 */
const ColumnChart = memo(
  ({
    title = '', // Título del gráfico
    subtitle = '', // Subtítulo del gráfico
    data = [], // Datos a mostrar
    colors = [], // Colores de las columnas
    type = 'column', // Tipo de gráfico
    stacking = '', // Apilado de las columnas
    tooltipFormat = '{series.name}: {point.y}', // Formato del tooltip
    exportOptions = true, // Muestra las opciones de exportación
    enableDataLabels = true, // Muestra los valores de cada columna
    showLegend = true, // Muestra la leyenda
    height = '', // Altura del gráfico
    spacing = [0, 0, 0, 0], // Espaciado entre los bordes del gráfico y el contenido
    shadow = false, // Sombra de las columnas
    borderRadius = 0, // Redondeado de las esquinas de las columnas
    borderWidth = 0, // Ancho del borde de las columnas
    yAxisTitle = '', // Título del eje Y
    xAxisCategories = '', // Categorías del eje X
    gridLineWidth = 0, // Ancho de las líneas de la grilla
    valueSuffix = '', // Sufijo de los valores
    chartRef = null, // Referencia al gráfico
  }) => {
    const options = {
      ...generalOptionsHighCharts,
      exporting: {
        enabled: exportOptions,
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadJPEG',
              'downloadSVG',
              'downloadPDF',
              'separator',
              ...(exportOptions ? ['downloadCSV'] : []),
            ],
          },
          filename: title,
        },
        menuItemDefinitions: {
          viewFullscreen: {
            text: 'Ver en pantalla completa',
          },
          printChart: {
            text: 'Imprimir',
          },
          downloadPDF: {
            text: 'Descargar PDF',
          },
          downloadCSV: {
            text: 'Descargar CSV',
          },
          downloadJPEG: {
            text: 'Descargar JPEG',
          },
          downloadSVG: {
            text: 'Descargar SVG',
          },
        },
        csv: {
          dateFormat: '%Y-%m-%d',
          itemDelimiter: ';',
          lineDelimiter: '\n',
          decimalPoint: ',',
        },
      },
      chart: {
        type: type,
        ZoomType: 'x',
        height: height,
        spacingBottom: spacing[2],
        spacingLeft: spacing[3],
        spacingRight: spacing[1],
        spacingTop: spacing[0],
      },
      title: {
        text: title,
        useHTML: true,
      },
      subtitle: {
        text: subtitle,
      },
      legend: {
        enabled: showLegend, // Controla si se muestran o no las leyendas
        reversed: false, // Invierte el orden de las leyendas
      },
      xAxis: {
        categories: data.length > 0 ? xAxisCategories : undefined,
        crosshair: false,
        dataLabels: {
          enabled: enableDataLabels,
        },
      },
      yAxis: {
        allowDecimals: false,
        // min: 0,
        title: {
          text: yAxisTitle,
        },
        gridLineWidth: gridLineWidth,
        softMin: undefined, // Permite que el eje se ajuste automáticamente a valores negativos
        softMax: undefined, // Permite que el eje se ajuste automáticamente a valores positivos
        // Añadir línea en el cero
        plotLines: [
          {
            value: 0,
            color: '#888888',
            width: 1,
            zIndex: 5,
          },
        ],
      },

      series: data,

      plotOptions: {
        column: {
          shadow: shadow,
          borderRadius: borderRadius ? `${borderRadius}%` : '0',
          borderWidth: borderWidth,
          stacking: stacking,
          pointPadding: 0.2, // Espacio entre columnas dentro del grupo
          groupPadding: 0.04, // Espacio entre grupos de columnas

          dataLabels: {
            enabled: enableDataLabels,
            format: stacking === 'percent' ? '{point.percentage:.0f}%' : null,
          },
        },
        series: {
          dataLabels: {
            allowOverlap: true,
          },
          negativeFillColor: undefined, // Color de relleno para valores negativos
          threshold: 0, // Nivel donde las columnas cambian de dirección
        },

        bar: {
          shadow: shadow,
          borderRadius: borderRadius ? `${borderRadius}%` : '0',
          borderWidth: borderWidth,
          stacking: stacking,
          pointpadding: 0.09,
          groupPadding: 0.04,
          dataLabels: {
            enabled: enableDataLabels,
            format: stacking === 'percent' ? '{point.percentage:.0f}%' : null,
          },
        },
      },
      colors: colors.length > 0 ? colors : undefined,
      tooltip: {
        pointFormat: tooltipFormat,
        valueSuffix: valueSuffix,
      },
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    );
  }
);

export default ColumnChart;
