import { Box, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/styles';
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from 'components';
import { ColumnChart } from 'components/Charts';
import { useRef, useState } from 'react';

const NutritionChart = ({ nutritionData, loadingData }) => {
  const chartRef = useRef(null);

  const theme = useTheme();

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  const colors = [
    '#FFCDD2',
    '#F8BBD0',
    '#E1BEE7',
    '#D1C4E9',
    '#C5CAE9',
    '#BBDEFB',
    '#B3E5FC',
    '#B2EBF2',
    '#B2DFDB',
  ];

  console.log(nutritionData, '--- en el chart');

  return (
    <Card
      elevation={0}
      variant='outlined'
      sx={{ borderColor: theme.palette.grey[200] }}
    >
      {loadingData && <LoadingWidget />}
      {!loadingData && nutritionData && (
        <CardContent>
          <HighchartsWrapper
            fullScreenHandler={openFullscreen}
            title='Índice DRIS Cerezo'
          >
            <ColumnChart
              // title='Índice DRIS Cerezo'
              data={nutritionData}
              xAxisCategories={
                (nutritionData &&
                  nutritionData[0]?.data?.map((item) => item.name)) ||
                []
              }
              chartRef={chartRef}
              colors={colors}
              yAxisTitle='Indice DRIS' // Titulo del eje Y
              type='column' // column o bar
            />
          </HighchartsWrapper>
        </CardContent>
      )}
    </Card>
  );
};

export default NutritionChart;
