import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createBreakpoint } from 'react-use';
import { useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import {
  numberFormat,
  isNumber,
  findActualYear,
  IsDesktopHandler,
  addCcQuarterListToData,
  numberFormatDecimals,
  alphabeticalSort,
  getOrchardsBySpecie,
  alterArrayForMulti,
  alterArrayForSelectVariety,
  addCcQuarterVarietyListToData,
} from '../../../helpers';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
// import { Context } from '../../context/Context';
import { NutritionData, NutritionChart } from 'containers/Nutrition';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  CardPaper,
} from '../../../components';
import { MobileFilters } from 'containers/Library';
import { useErrorHandler } from 'react-error-boundary';
// import CuarterIrrigationData from 'containers/Irrigation/CuarterIrrigationData';

const useBreakpoint = createBreakpoint({
  xl: 1563,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 0,
});

const responseNutrition = {
  idUnitProductive: 'CE-LG-3',
  season: '2024',
  clientValue: 'Andres_Ureta',
  orchardValue: 'Los_Gomeros',
  quarterValue: '4-Lapins',
  varietyValue: 'Lapins',
  rootStockValue: 'Colt',
  I_N: -407.119881878,
  I_K: -217.672090598,
  I_Zn: 1050.820869517,
  I_P: -612.223072757,
  I_Ca: 15.696563101,
  I_B: 200.380209094,
  I_Cu: -43.182113881,
  I_Mn: -29.993580429,
  I_Mg: -504.475190346,
  I_Fe: -56.750618313,
  orderI: [
    'I_N',
    'I_K',
    'I_Zn',
    'I_P',
    'I_Ca',
    'I_B',
    'I_Cu',
    'I_Mn',
    'I_Mg',
    'I_Fe',
  ],
};

const nutritionGraphOrder = [
  'I_N',
  'I_P',
  'I_K',
  'I_Ca',
  'I_Mg',
  'I_Fe',
  'I_Mn',
  'I_Zn',
  'I_Cu',
  'I_B',
];

const chartData = [
  { name: 'España', y: 83.6 },
  { name: 'Francia', y: 88.9 },
  { name: 'Alemania', y: 39.1 },
  { name: 'Italia', y: -60.4 },
  { name: 'Reino Unido', y: 36.4 },
  { name: 'Dinamarca', y: -35.4 },
];

//esta funcion es para cuando la serie de datos es un objeto simple

const useStyles = makeStyles((theme) => ({
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.35rem',
      fontSize: 14,
    },
  },
  activeButton: {
    backgroundColor: 'rgba(127,146,167, 0.3) !important',
  },
  infoCol: {
    fontWeight: 500,
  },
  animatedCardStyle: {
    position: 'absolute',
    bottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    zIndex: 100,
    transform: 'translateY(250px)',
    transition: 'transform 0.3s ease-in-out',
    '&.active': {
      transform: 'translateY(0px)',
    },
  },
  flexChildsStyle: {
    '& > *': {
      flex: 1,
    },
  },
  buttonAreaStyle: {
    width: '100%',
    '&.active': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.main,
    },
  },
}));

const moduleOrchardFilter = 'statusOrchard';

const nutritionDataEndpoint = 'v1/nutrition';

const Nutrition = (props) => {
  const { window } = props;

  // console.log(window, '-----------------------', width);

  const errorHandler = useErrorHandler();

  const axiosContext = useContext(AxiosContext);

  const { userClient } = useContext(Context);

  const { state: filterState } = useContext(FiltersContext);

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    idUnitProductive,
    isLoading,
  } = filterState;

  const classes = useStyles();

  const [loadingFilterState, setLoadingFilterState] = useState(false);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter])
  );

  const [orchardDataMenuState, setOrchardDataMenuState] = useState(null);

  const [quarterDataState, setQuarterDataState] = useState([]);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [nutritionDataState, setNutritionDataState] = useState(null);

  const [nutritionDataChartState, setNutritionDataChartState] = useState(null);

  const [loadingNutritionState, setLoadingNutritionState] = useState(false);

  const [noDataMessageState, setNoDataMessageState] = useState('');

  const theme = useTheme();

  // console.log(theme, '--------theme');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  useEffect(() => {
    if (useOrchard) {
      setOrchardSelectedState(useOrchard.id);
    }

    const resultOrchardCopy = useOrchard
      ? JSON.parse(JSON.stringify(useOrchard))
      : {};

    let newObjData = {};
    newObjData = addCcQuarterVarietyListToData(
      resultOrchardCopy,
      specie_filter
    );

    setOrchardDataMenuState(newObjData);

    setValue('cc', null);

    // TODO: alimentar el select de cuarteles
    setQuarterDataState(alphabeticalSort(newObjData?.quarterList, 'cc_label'));
  }, [useOrchard]);

  // crear función que recibe responseNutrition y que con el orden de nutritionGraphOrder arma el formato igual a chartData

  const getNutritionDataChart = (data) => {
    const dataSeries = nutritionGraphOrder.map((item) => {
      const cutName = item.replace('I_', '');
      return {
        name: cutName,
        y: data[item],
      };
    });

    return dataSeries;
  };

  async function queryData(idUnitProductive) {
    setLoadingNutritionState(true);

    try {
      const params = {
        clientValue: userClient.value,
        idUnitProductive: idUnitProductive,
      };

      const response = await axiosContext.authAxios.get(nutritionDataEndpoint, {
        params,
      });

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        if (data.length) {
          setNutritionDataState(data[0]);

          const dataChart = getNutritionDataChart(data[0]);

          const dataSeries = [
            {
              name: 'índice',
              data: dataChart,
              colorByPoint: true,
              // legend: false,

              dataLabels: {
                format: '{point.y:.1f}',
              },
            },
          ];

          setNutritionDataChartState(dataSeries);
        } else {
          setNutritionDataState(null);
          setNutritionDataChartState(null);
          setNoDataMessageState('No hay datos para mostrar');
        }
      }
    } catch (err) {
      setNutritionDataState(null);
      setNutritionDataChartState(null);
      errorHandler(err);
    } finally {
      setLoadingNutritionState(false);
    }
  }

  const onSubmit = async (values) => {
    // handleQuarterClick(values.quarter.cc_data, values.quarter.quarter_data);

    console.log(values, '------------------values');
    setNutritionDataState(null);

    const idUnitProductiveSelected = values.cc.idUnitProductive;

    queryData(idUnitProductiveSelected);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className={clsx('form-inline', classes.flexChildsStyle)}
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFilterState ? (
            <>
              <div className='input-group mb-2'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFilterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={false}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='cc'
                  display='vertical'
                  containerStyle={{ width: '100%' }}
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={quarterDataState}
                  placeholder='CC / Cuartel / Variedad'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFilterState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  const areaHandler = (area) => {
    setActiveAreaState(area);
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='mb-2 mb-md-5'>
          <BsCol class='col-lg-12 mb-2 mb-lg-0'>
            <Card elevation={0} variant='outlined'>
              <CardContent
                sx={{
                  paddingBottom: '8 px !important',
                }}
              >
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Nutrición
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-8'>{renderFilters()}</BsCol>
                </BsRow>
              </CardContent>
            </Card>
            {/* <Box className='mt-3'>
              <CardPaper
                elevation={0}
                variant='outlined'
                sx={{
                  padding: 1.5,
                  height: '100%',
                  backgroundColor: theme.palette.grey[50],
                }}
              >
                <Typography gutterBottom variant='h5' component='h2'>
                  Información de la Estación
                </Typography>
                {loadingIrrigationState && <LoadingWidget />}
                {irrigationDataState && irrigationDataState.length ? (
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Serial</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.serial || ''}
                        </td>
                      </tr>
                      <tr>
                        <td>Especie</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.specie || ''}
                        </td>
                      </tr>
                      <tr>
                        <td>CC</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.cc || ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
                {irrigationDataState && irrigationDataState.length === 0 ? (
                  <Typography
                    gutterBottom
                    variant='h6'
                    component='h2'
                    className='text-center mt-3'
                  >
                    No hay datos para mostrar
                  </Typography>
                ) : null}
              </CardPaper>
            </Box> */}
          </BsCol>
          <BsCol class='col-lg-12 mt-4'>
            {noDataMessageState && (
              <Typography
                gutterBottom
                variant='h4'
                component='h2'
                className='text-center mt-3'
              >
                {noDataMessageState}
              </Typography>
            )}
            <>
              <Box className=''>
                <NutritionData
                  nutritionData={nutritionDataState}
                  loadingData={loadingNutritionState}
                />
              </Box>
              <Box className='mt-3'>
                <NutritionChart
                  nutritionData={nutritionDataChartState}
                  loadingData={loadingNutritionState}
                />
              </Box>
            </>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Nutrition;
