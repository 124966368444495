import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Card,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { createBreakpoint } from 'react-use';
import { useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import {
  numberFormat,
  isNumber,
  findActualYear,
  IsDesktopHandler,
  addCcQuarterListToData,
  numberFormatDecimals,
  alphabeticalSort,
  getOrchardsBySpecie,
  alterArrayForMulti,
  alterArrayForSelectVariety,
} from '../../../helpers';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
// import { Context } from '../../context/Context';
import {
  Map,
  CuarterIrrigationData,
  IrrigationChart,
} from 'containers/Irrigation';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  CardPaper,
} from '../../../components';
import { MobileFilters } from 'containers/Library';
import { useErrorHandler } from 'react-error-boundary';
// import CuarterIrrigationData from 'containers/Irrigation/CuarterIrrigationData';

const useBreakpoint = createBreakpoint({
  xl: 1563,
  lg: 1200,
  md: 900,
  sm: 600,
  xs: 0,
});

const useStyles = makeStyles((theme) => ({
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.35rem',
      fontSize: 14,
    },
  },
  activeButton: {
    backgroundColor: 'rgba(127,146,167, 0.3) !important',
  },
  infoCol: {
    fontWeight: 500,
  },
  animatedCardStyle: {
    position: 'absolute',
    bottom: 0,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    zIndex: 100,
    transform: 'translateY(250px)',
    transition: 'transform 0.3s ease-in-out',
    '&.active': {
      transform: 'translateY(0px)',
    },
  },
  flexChildsStyle: {
    '& > *': {
      flex: 1,
    },
  },
  buttonAreaStyle: {
    width: '100%',
    '&.active': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary.main,
    },
  },
}));

const moduleOrchardFilter = 'statusOrchard';

// const responseIrrigation = [
//   {
//     idUnitProductive: 'GF-ALM-26',
//     client: 'Garces Fruit',
//     clientValue: 'Garces_Fruit',
//     agroBusiness: 'HGE SPA',
//     agroBusinessValue: 'HGE_SPA',
//     orchard: 'Almahue',
//     orchardValue: 'Almahue',
//     specie: 'Cerezo',
//     specieValue: 'Cerezo',
//     cc: '10328-3',
//     ccValue: '10328-3',
//     quarter: 'E1-S1-4',
//     quarterValue: 'E1-S1-4',
//     variety: 'Lapins',
//     varietyValue: 'Lapins',
//     rootStock: 'Colt',
//     rootStockValue: 'Colt',
//     humidityStationSerial: 'sw3_1287387123',
//     dbM3: 239.088093254,
//     SWC30: 12, // El ultimo del dia. Los tres son array y me quedo con el ultimo.
//     SWC60: 12, // El ultimo del dia. Los tres son array y me quedo con el ultimo.
//     SWC90: 12, // El ultimo del dia. Los tres son array y me quedo con el ultimo.
//   },
//   {
//     idUnitProductive: 'GF-ALM-23',
//     client: 'Garces Fruit',
//     clientValue: 'Garces_Fruit',
//     agroBusiness: 'HGE SPA',
//     agroBusinessValue: 'HGE_SPA',
//     orchard: 'Almahue',
//     orchardValue: 'Almahue',
//     specie: 'Cerezo',
//     specieValue: 'Cerezo',
//     cc: '10328-3',
//     ccValue: '10328-3',
//     quarter: 'E1-S1',
//     quarterValue: 'E1-S1',
//     variety: 'Lapins',
//     varietyValue: 'Lapins',
//     rootStock: 'Colt',
//     rootStockValue: 'Colt',
//     humidityStationSerial: 'sw3_1287387124',
//     dbM3: 240.088093254,
//     SWC30: 15,
//     SWC60: 15,
//     SWC90: 15,
//   },
//   {
//     idUnitProductive: 'GF-ALM-22',
//     client: 'Garces Fruit',
//     clientValue: 'Garces_Fruit',
//     agroBusiness: 'HGE SPA',
//     agroBusinessValue: 'HGE_SPA',
//     orchard: 'Almahue',
//     orchardValue: 'Almahue',
//     specie: 'Cerezo',
//     specieValue: 'Cerezo',
//     cc: '10328-3',
//     ccValue: '10328-3',
//     quarter: 'E1-S4',
//     quarterValue: 'E1-S4',
//     variety: 'Lapins',
//     varietyValue: 'Lapins',
//     rootStock: 'Colt',
//     rootStockValue: 'Colt',
//     humidityStationSerial: 'sw3_1287387125',
//     dbM3: 241.088093254,
//     SWC30: 18,
//     SWC60: 18,
//     SWC90: 18,
//   },
// ];

const irrigationTableEndpoint = 'v1/irrigation/table';

const Station = (props) => {
  const { window } = props;

  const breakpoint = useBreakpoint();

  // console.log(window, '-----------------------', width);

  const history = useHistory();

  const errorHandler = useErrorHandler();

  const axiosContext = useContext(AxiosContext);

  const { userClient } = useContext(Context);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const {
    orchard_filter,
    orchard_id_filter,
    userOrchard_filter,
    cc_filter,
    quarter_filter,
    specie_filter,
    idUnitProductive,
    isLoading,
  } = filterState;
  const [buttonClickState, setButtonClickState] = useState(false);

  const classes = useStyles();

  const [loadingFilterState, setLoadingFilterState] = useState(false);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter])
  );

  const [orchardDataMenuState, setOrchardDataMenuState] = useState(null);

  const [ccDataState, setCcDataState] = useState([]);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);
  const [ccSelectedState, setCcSelectedState] = useState(null);
  const [quarterSelectedState, setQuarterSelectedState] = useState(null);

  const [drawerPolygonSelectedState, setDrawerPolygonSelectedState] =
    useState(null);

  const [irrigationDataState, setIrrigationDataState] = useState(null);

  const [loadingIrrigationState, setLoadingIrrigationState] = useState(false);

  const theme = useTheme();

  // console.log(theme, '--------theme');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useQuarter = useWatch({
    control,
    name: 'quarter',
  });

  useEffect(() => {
    if (orchard_filter) {
      console.log(orchard_filter, '------------------orchard_filter');

      const resultOrchard = userOrchard_filter.find(
        (ele) => ele.value === orchard_filter
      );

      setValue('orchard', resultOrchard);
    }
  }, [orchard_filter]);

  const clickButtonHandler = () => {
    setButtonClickState(!buttonClickState);
  };

  useEffect(() => {
    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.id === orchard_id_filter
    );

    if (resultOrchard.length) {
      setOrchardSelectedState(resultOrchard[0].id);
    }

    const resultOrchardCopy = resultOrchard[0]
      ? JSON.parse(JSON.stringify(resultOrchard[0]))
      : {};

    // let newObjData = {};

    // console.log(resultOrchardCopy, '----ORCHARD--');

    // console.log(
    //   alterArrayForSelectVariety(resultOrchardCopy.cc),
    //   '-----------CCs----'
    // );

    // newObjData = addCcQuarterListToData(resultOrchardCopy, specie_filter);

    setOrchardDataMenuState(resultOrchardCopy);

    setValue('cc', '');

    // console.log(newObjData, '------------------newObjData');

    // TODO: alimentar el select de cuarteles
    if (resultOrchardCopy && resultOrchardCopy.cc) {
      setCcDataState(
        alphabeticalSort(
          alterArrayForSelectVariety(resultOrchardCopy.cc),
          'label'
        )
      );
    }
  }, [orchard_id_filter, useOrchard]);

  // console.log(searchResultsQuarter, '<--------------searchResultsQuarter');

  const handleButtonClick = (idOrchard, value, type) => {
    // console.log(idOrchard, value, type, '<------------------CLICK EN BOTON');
    setCcSelectedState(null);
    setQuarterSelectedState(null);
    if (orchard_id_filter !== idOrchard) {
      addFilter({
        cc_filter: '',
        quarter_filter: '',
        orchard_id_filter: '',
        idUnitProductive: '',
      });
    }
    addFilter({
      orchard_filter: value,
      orchard_id_filter: idOrchard,
    });
    if (type === 2) {
      setOrchardSelectedState(idOrchard);
      // console.log(widthWindow, '--------------widthWindow');
    }

    console.log('handle Click con cambio Orchard');

    clickButtonHandler();
  };

  const handleQuarterClick = (cc, quarter) => {
    console.log(cc, quarter, '------------------handleQuarterClick');

    setCcSelectedState(cc);
    setQuarterSelectedState(quarter);
    setDrawerPolygonSelectedState({
      cc,
      quarter,
    });
    addFilter({
      cc_filter: cc.value,
      quarter_filter: quarter.value,
    });
    // setActiveCardInfoState(true);
  };

  useEffect(() => {
    if (cc_filter && quarter_filter && orchardDataMenuState) {
      // console.log(
      // 	orchardDataMenuState,
      // 	'-------',
      // 	cc_filter,
      // 	'------',
      // 	quarter_filter
      // );
      const ccSelectedMap = orchardDataMenuState.cc.filter(
        (ele) => ele.value === cc_filter
      );

      let quarterSelectedMap = null;

      if (ccSelectedMap.length) {
        quarterSelectedMap = ccSelectedMap[0].quarter.filter(
          (ele) => ele.value === quarter_filter
        );
      }

      // console.log(
      // 	ccSelectedMap,
      // 	'--------------------------',
      // 	quarterSelectedMap
      // );

      setOrchardSelectedState(orchard_id_filter);
      setCcSelectedState(ccSelectedMap[0]);
      setQuarterSelectedState(
        quarterSelectedMap ? quarterSelectedMap[0] : null
      );
    }
  }, [cc_filter, quarter_filter]);

  const multiVarietalQuarter = (varieties) => {
    const renderVariety = varieties.map((variety, i) => {
      return (
        <React.Fragment key={variety?.label}>
          <tr>
            <td>Variedad {i + 1}</td>
            <td className={classes.infoCol}>{variety?.label}</td>
          </tr>
          <tr>
            <td>Portainjerto {i + 1}</td>
            <td className={classes.infoCol}>{variety.rootStock}</td>
          </tr>
        </React.Fragment>
      );
    });
    return renderVariety;
  };

  const actionHandler = useCallback(
    (orchard) => {
      handleButtonClick(orchard.id, orchard.value, 2);
    },
    [handleButtonClick, useOrchard]
  );

  useEffect(() => {
    console.log('entra en el useEffect de useOrchard 1', useOrchard);
    if (useOrchard) {
      actionHandler(useOrchard);
      setValue('quarter', '');
    } else {
      setOrchardSelectedState(null);
      setCcDataState([]);
      setValue('quarter', '');
    }
  }, [useOrchard]);

  // const infoOrchardQuarterBox = (
  //   <div>
  //     {Boolean(orchardSelectedState) ? (
  //       <>
  //         <div>
  //           <Typography variant='h5' component='h2' color='secondary'>
  //             Información Cuartel
  //           </Typography>
  //           {/* {console.log(
  // 									orchardDataMenuState,
  // 									'------------------orchardDataMenuState'
  // 								)} */}
  //           {orchardDataMenuState && (
  //             <table
  //               className={clsx(
  //                 'table table-borderless table-sm',
  //                 classes.tableStyles
  //               )}
  //             >
  //               <tbody>
  //                 <tr>
  //                   <td
  //                     style={{
  //                       width: '35%',
  //                     }}
  //                   >
  //                     Huerto
  //                   </td>
  //                   <td className={classes.infoCol}>
  //                     {orchardDataMenuState.label}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Centro de costo</td>
  //                   <td className={classes.infoCol}>
  //                     {ccSelectedState && ccSelectedState.label}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Cuartel</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState && quarterSelectedState.label}
  //                   </td>
  //                 </tr>
  //                 {quarterSelectedState &&
  //                 quarterSelectedState?.variety.length > 1 ? (
  //                   multiVarietalQuarter(quarterSelectedState?.variety)
  //                 ) : (
  //                   <>
  //                     <tr>
  //                       <td>Variedad</td>
  //                       <td className={classes.infoCol}>
  //                         {quarterSelectedState &&
  //                           quarterSelectedState?.variety[0]?.label}
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>Portainjerto</td>
  //                       <td className={classes.infoCol}>
  //                         {quarterSelectedState &&
  //                           quarterSelectedState?.variety[0]?.rootStock}
  //                       </td>
  //                     </tr>
  //                   </>
  //                 )}
  //                 <tr>
  //                   <td>Hectárea</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormatDecimals(
  //                         quarterSelectedState?.variety[0]?.hectare,
  //                         2
  //                       )}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Plantas/há</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormat(
  //                         quarterSelectedState?.variety[0]?.plantsHa
  //                       )}
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>Plantas Totales</td>
  //                   <td className={classes.infoCol}>
  //                     {quarterSelectedState &&
  //                       numberFormatDecimals(
  //                         quarterSelectedState?.variety[0]?.plantsHa *
  //                           quarterSelectedState?.variety[0]?.hectare,
  //                         0
  //                       )}
  //                   </td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           )}
  //         </div>
  //       </>
  //     ) : null}
  //   </div>
  // );

  const isDesktop = IsDesktopHandler('lg');

  async function queryData(orchard, cc) {
    setLoadingIrrigationState(true);

    // await 3 seconds and ste state
    // setTimeout(() => {
    //   setIrrigationDataState(responseIrrigation);
    //   setLoadingIrrigationState(false);
    // }, 3000);

    try {
      const params = {
        clientValue: userClient.value,
        specieValue: specie_filter,
        orchardValue: orchard,
        ccValue: cc,
      };

      const response = await axiosContext.authAxios.get(
        irrigationTableEndpoint,
        {
          params,
        }
      );

      // // let data = true;

      const { data, status } = response;

      console.log(data, '<----data en orchards parameters');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setIrrigationDataState(data);
      }
    } catch (err) {
      setIrrigationDataState(null);
      errorHandler(err);
    } finally {
      setLoadingIrrigationState(false);
    }
  }

  const onSubmit = async (values) => {
    // handleQuarterClick(values.quarter.cc_data, values.quarter.quarter_data);

    console.log(values, '------------------values');
    setIrrigationDataState(null);
    // setCcSelectedState(null);

    addFilter({
      quarter_filter: '',
      idUnitProductive: '',
    });

    setCcSelectedState(values.cc.value);
    queryData(values.orchard.value, values.cc.value);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className={clsx('form-inline', classes.flexChildsStyle)}
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFilterState ? (
            <>
              <div className='input-group mb-2'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFilterState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  containerStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
              <div className='input-group ml-md-2 mb-2'>
                <SelectMultipleWidget2
                  errors={errors.cc}
                  loading={false}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  req
                  name='cc'
                  display='vertical'
                  containerStyle={{ width: '100%' }}
                  variant='outlined'
                  isMulti={false}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={ccDataState}
                  placeholder='Centros de Costo'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFilterState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  const areaHandler = (area) => {
    setActiveAreaState(area);
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='mb-2 mb-md-5'>
          <BsCol class='col-lg-6 mb-2 mb-lg-0'>
            <Card elevation={0} variant='outlined'>
              <CardContent
                sx={{
                  paddingBottom: '8 px !important',
                }}
              >
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Mapa
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-12'>{renderFilters()}</BsCol>
                </BsRow>
              </CardContent>
            </Card>
            <Box className='mt-3'>
              <CardPaper
                elevation={0}
                variant='outlined'
                sx={{
                  padding: 1.5,
                  height: '100%',
                  backgroundColor: theme.palette.grey[50],
                }}
              >
                <Typography gutterBottom variant='h5' component='h2'>
                  Información de la Estación
                </Typography>
                {loadingIrrigationState && <LoadingWidget />}
                {irrigationDataState && irrigationDataState.length ? (
                  <table className='table mb-0'>
                    <tbody>
                      <tr>
                        <td>Serial</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.serial || ''}
                        </td>
                      </tr>
                      <tr>
                        <td>Especie</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.specie || ''}
                        </td>
                      </tr>
                      <tr>
                        <td>CC</td>
                        <td className={classes.infoCol}>
                          {irrigationDataState[0]?.cc || ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
                {irrigationDataState && irrigationDataState.length === 0 ? (
                  <Typography
                    gutterBottom
                    variant='h6'
                    component='h2'
                    className='text-center mt-3'
                  >
                    No hay datos para mostrar
                  </Typography>
                ) : null}
              </CardPaper>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                mt: 2,
                height: {
                  xs: '58vh',
                  sm: '60vh',
                  md: '70vh',
                  lg: '75vh',
                },
                borderRadius: theme.shape.borderRadius + 'px',
                overflow: 'hidden',
              }}
            >
              <Map
                buttonClick={buttonClickState}
                orchardHandler={handleButtonClick}
                quarterSelected={drawerPolygonSelectedState}
                orchardSelected={orchardSelectedState}
                irrigationData={irrigationDataState}
                isLgWindow={isDesktop}
              />
              {/* {quarterSelectedState ? (
                <CardPaper
                  className={clsx(
                    classes.animatedCardStyle,
                    activeCardInfoState ? 'active' : ''
                  )}
                  variant='outlined'
                  sx={{
                    ml: 2,
                    mr: 6,
                    width: '-webkit-fill-available',
                  }}
                >
                  <div>
                    <Grid container columnSpacing={2} sx={{ width: '100%' }}>
                      <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {activeCardInfoState ? (
                          <IconButton
                            aria-label='Abrir'
                            size='small'
                            onClick={() => setActiveCardInfoState(false)}
                          >
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label='Cerrar'
                            size='small'
                            onClick={() => setActiveCardInfoState(true)}
                          >
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    {infoOrchardQuarterBox}
                  </div>
                </CardPaper>
              ) : null} */}
            </Box>
          </BsCol>
          <BsCol class='col-lg-6'>
            {/* <Grid container spacing={2} columns={{ xs: 4, md: 5, lg: 10 }}>
              <Grid
                item
                xs={2}
                md={2}
                className='text-center'
                onClick={() => areaHandler(1)}
              >
                <Button
                  variant='outlined'
                  className={clsx(
                    classes.buttonAreaStyle,
                    activeAreaState === 1 ? 'active' : null
                  )}
                  onClick={() => areaHandler(1)}
                >
                  Rendimiento
                </Button>
              </Grid>
              <Grid item xs={2} md={2} className='text-center'>
                <Button
                  variant='outlined'
                  className={clsx(
                    classes.buttonAreaStyle,
                    activeAreaState === 2 ? 'active' : null
                  )}
                  onClick={() => areaHandler(2)}
                >
                  Conteo
                </Button>
              </Grid>
              <Grid item xs={2} md={2} className='text-center'>
                <Button
                  variant='outlined'
                  className={clsx(
                    classes.buttonAreaStyle,
                    activeAreaState === 3 ? 'active' : null
                  )}
                  onClick={() => areaHandler(3)}
                >
                  Clima
                </Button>
              </Grid>
              <Grid item xs={2} md={2} className='text-center'>
                <Button
                  variant='outlined'
                  disabled
                  className={classes.buttonAreaStyle}
                >
                  Nutrición
                </Button>
              </Grid>
              <Grid item xs={2} md={2} className='text-center'>
                <Button
                  variant='outlined'
                  disabled
                  className={classes.buttonAreaStyle}
                >
                  Riego
                </Button>
              </Grid>
            </Grid> */}
            <Box className=''>
              <CuarterIrrigationData
                irrigationData={irrigationDataState}
                loadingData={loadingIrrigationState}
              />
            </Box>
            <Box className='mt-3'>
              <IrrigationChart
                orchardSelected={orchardSelectedState}
                ccSelected={ccSelectedState}
              />
            </Box>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default Station;
