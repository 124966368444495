// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import {
  Box,
  useTheme,
  CardContent,
  Typography,
  Divider,
  Button,
  Grid,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

// Internal modules
import { Context } from '../../../context/Context';

// Components
import { CardPaper } from '../../../components';
import { numberFormatDecimals } from 'helpers';

// Styles

const useStyles = makeStyles((theme) => ({
  theadLight: {
    '& th': {
      // color: '#495057',
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
  },
}));

const yearsArray = Array.from(
  { length: 4 },
  (_, i) => new Date().getFullYear() - i
)
  .map((year) => year.toString())
  .reverse();

const NutritionData = ({ nutritionData, loadingData }) => {
  // const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const classes = useStyles();

  console.log(nutritionData, '--- en la tabla');

  const responseNutrition = {
    idUnitProductive: 'CE-LG-3',
    season: '2024',
    clientValue: 'Andres_Ureta',
    orchardValue: 'Los_Gomeros',
    quarterValue: '4-Lapins',
    varietyValue: 'Lapins',
    rootStockValue: 'Colt',
    I_N: -407.119881878,
    I_K: -217.672090598,
    I_Zn: 1050.820869517,
    I_P: -612.223072757,
    I_Ca: 15.696563101,
    I_B: 200.380209094,
    I_Cu: -43.182113881,
    I_Mn: -29.993580429,
    I_Mg: -504.475190346,
    I_Fe: -56.750618313,
    orderI: [
      'I_N',
      'I_K',
      'I_Zn',
      'I_P',
      'I_Ca',
      'I_B',
      'I_Cu',
      'I_Mn',
      'I_Mg',
      'I_Fe',
    ],
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item>
          <Typography variant='h5' gutterBottom>
            Tabla
          </Typography>
        </Grid>
        <Grid item>
          {/* <Button
              color='primary'
              variant='contained'
              size='small'
              disabled={!tableDataState || loadingState}
              onClick={(e) =>
                downloadXLSFormat(
                  {
                    headers: HEADERS_EXPORT,
                    data: tableDataState,
                  },
                  'Rendimientos'
                )
              }
            >
              EXCEL
            </Button> */}
        </Grid>
      </Grid>
      <Divider className='mt-3' />
      <div className='table-responsive'>
        {loadingData && (
          <Skeleton
            variant='rectangular'
            // animation='wave'
            // width={100%}
            height={80}
          />
        )}
        <table className='table table-bordered table-striped'>
          <thead className={classes.theadLight}>
            {nutritionData && (
              <tr>
                <th>Nutriente</th>
                <th>Temporada</th>
                <th>Portainjerto</th>
                {nutritionData.orderI.map((item, index) => {
                  const cutName = item.replace('I_', '');
                  return <th key={index}>{cutName}</th>;
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {nutritionData && (
              <tr>
                <td>Orden Fertilización</td>
                <td>{nutritionData?.season}</td>
                <td>{nutritionData?.rootStockValue}</td>
                {nutritionData.orderI.map((item, index) => (
                  <td key={index}>
                    {numberFormatDecimals(nutritionData[item], 1)}
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {nutritionData === null && !loadingData && (
        <div className='d-flex justify-content-center align-items-center mt-4'>
          <Typography variant='h6'>Seleccione un Cuartel</Typography>
        </div>
      )}
    </>
  );
};

export default NutritionData;
