import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
} from 'google-maps-react';
import { createRoot } from 'react-dom/client';
import React, {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Backdrop,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Link,
  SvgIcon,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FiltersContext } from '../../../context';
import {
  alterArrayAddColor,
  dashDelete,
  getCoords,
  getCoordsByGeom,
  getValidPlant1ById,
  getValidPolygons,
  getValidPolygonsById,
} from '../../../helpers/functionHelpers';

import treeMarker from '../../../assets/planta1.png';

const useStyles = makeStyles((theme) => ({
  infoOrchardStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    padding: '8px 20px',
  },
  infoCuarterStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '& table td': {
      fontSize: 14,
    },
  },
  irButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  overlayButton: {
    position: 'fixed',
    top: '240px',
    right: '16px',
    [theme.breakpoints.up('lg')]: {
      right: 'calc(16px + 380px)',
    },
    zIndex: 979,
  },
  overlayButtonNoDrawer: {
    position: 'fixed',
    top: '240px',
    right: '16px',
    zIndex: 979,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const moduleOrchardFilter = 'statusOrchard';

const MapComponent = (props) => {
  const MapRef = useRef();
  // const PolygonRef = useRef();

  // const [getRef, setRef] = useDynamicRefs();
  const polygonRefs = useRef([]);

  const IconTree = {
    url: treeMarker, // URL del ícono personalizado
    scaledSize: new window.google.maps.Size(30, 30), // Tamaño del ícono
    origin: new window.google.maps.Point(0, 0), // Origen del ícono
    anchor: new window.google.maps.Point(15, 30), // Punto de anclaje del ícono
  };

  const {
    buttonClick,
    quarterSelected,
    library,
    libraryFilter,
    orchardSelected,
    orchardHandler,
    drawerAction,
    isLgWindow,
    irrigationData,
  } = props;

  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  let PolygonRef = [];

  // console.log(theme);
  const [mapState, setMapState] = useState(/** google.maps.Map */ null);

  const [imageBoundsState, setImageBoundsState] = useState(null);
  const [imageOverlayState, setImageOverlayState] = useState(null);

  const [showOverlayState, setShowOverlayState] = useState(false);
  const [overlayState, setOverlayState] = useState(null);

  const {
    state: {
      orchard_filter,
      orchard_id_filter,
      userOrchard_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showInfoWindowPolygon, setShowInfoWindowPolygon] = useState(false);
  const [showInfoWindowPolygonTooltip, setShowInfoWindowPolygonTooltip] =
    useState(false);
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState({});
  const [activePolygonState, setActivePolygonState] = useState({});
  const [activePolygonTooltipState, setActivePolygonTooltipState] = useState(
    {}
  );
  const [activeQuarterDataState, setActiveQuarterDataState] = useState(null);
  const [activeQuarterTooltipState, setActiveQuarterTooltipState] =
    useState(null);

  const [polygonFillColorState, setPolygonFillColorState] = useState([
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.9)',
  ]);

  const [plant1State, setPlant1State] = useState(null);
  const [plant1QuarterState, setPlant1QuarterState] = useState(null);

  // console.log(
  // 	orchard_filter,
  // 	'---------orchard_filter',
  // 	orchard_id_filter,
  // 	'-----',
  // 	userOrchard_filter
  // );

  const [quartersState, setQuartersState] = useState([]);

  const [mapInitialLocation, setMapInitialLocation] = useState({
    lat: -34.4142,
    lng: -70.7931,
  });

  const [initialZoom, setInitialZoom] = useState(15);

  const style = {
    width: '100%',
    height: '100%',
  };

  const onMarkerOver = (props, marker, e) => {
    // console.log('over marker', props, marker);
    // console.log(props.map.zoom, "<------el zoom")
    if (props.map.zoom < 14) {
      setSelectedPlace(props);
      setActiveMarker(marker);
      setShowInfoWindow(true);
      setShowInfoWindowPolygonTooltip(false);
    }
    // setSelectedPlace(props);
    // 	setActiveMarker(marker);
    // 	setShowInfoWindow(true);
    // 	setShowInfoWindowPolygonTooltip(false);
  };

  const onMarkerClickZoom = (props, marker, e) => {
    // console.log('over marker', props, marker);
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowInfoWindow(true);
    setShowInfoWindowPolygonTooltip(false);
  };

  console.log(isLgWindow, '<-----------------isLgWindow');

  const onMarkerClick = (marker) => {
    // console.log('clcik marker-----------', marker);
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    // orchardHandler();
    // desde aquí va con tipo 1
    orchardHandler(marker._id, marker.value, 1);
  };

  // const setPolygonOptions = (options) => {
  // 	PolygonRef.current.polygon.setOptions(options);
  // };
  let cuarterRef;

  const onPolygonClick = (props, marker, e, quarter) => {
    setShowInfoWindow(false);

    // console.log(quarter, marker, '-----click');

    // if (PolygonRef[0]) {
    //   for (let i = 0; i < quartersState.length; i++) {
    //     PolygonRef[i].polygon.setOptions({
    //       fillColor: polygonFillColorState[0],
    //     });
    //   }
    // }

    console.log(quarter, '<-------------------llega el quarter');

    // marker.setOptions({ fillColor: polygonFillColorState[1] });

    let orchardMap = quarter.orchard ? quarter.orchard : null;

    let ccMap = quarter.cc ? quarter.cc : null;
    let cuarterMap = quarter.quarter ? quarter.quarter : null;

    // ver si viene variety
    let varietyMap = quarter.variety ? quarter.variety : null;
    let idUnitProductiveMap = quarter.idUnitProductive
      ? quarter.idUnitProductive
      : null;

    if (orchardMap) {
      const resultOrchard = userOrchard_filter.filter(
        (ele) => ele.value === orchardMap
      );

      console.log(resultOrchard, '-----resultOrchard');

      addFilter({
        orchard_filter: orchardMap,
        orchard_id_filter: resultOrchard[0]?.id ? resultOrchard[0]?.id : '',
      });
    }

    if (ccMap) {
      addFilter({
        cc_filter: ccMap,
      });
    }

    if (cuarterMap) {
      addFilter({
        quarter_filter: cuarterMap,
      });
    } else {
      addFilter({
        quarter_filter: '',
      });
    }

    if (varietyMap) {
      addFilter({
        variety_filter: varietyMap,
      });
    } else {
      addFilter({
        variety_filter: '',
      });
    }

    if (idUnitProductiveMap) {
      addFilter({
        idUnitProductive: idUnitProductiveMap,
      });
    } else {
      addFilter({
        idUnitProductive: '',
      });
    }

    // console.log(e.latLng, "-------------latlng")
    // console.log('debe definir planta1 de este click', quarter);

    const plant1Quarter =
      plant1State && plant1State.length
        ? plant1State.filter(
            (item) => item.idUnitProductive === quarter.idUnitProductive
          )
        : [];

    // console.log(plant1Quarter, '----------------firstPlant1', plant1State);

    setPlant1QuarterState(plant1Quarter);

    setActivePolygonState(e.latLng);
    setActiveQuarterDataState(quarter);
    setShowInfoWindowPolygon(true);
    setShowInfoWindowPolygonTooltip(false);
    setActiveMarker({});
  };

  const onPolygonOver = (props, marker, e, quarter) => {
    // console.log('-------------onOVer-------', quarter, props);

    let orchardMap = quarter.orchard ? quarter.orchard : null;

    let ccMap = quarter.cc ? quarter.cc : null;
    let cuarterMap = quarter.quarter ? quarter.quarter : null;

    // console.log(quarter, '----------quarter');

    if (!showInfoWindowPolygon) {
      setActivePolygonTooltipState(e.latLng);
      setActiveQuarterTooltipState(quarter);
      setShowInfoWindowPolygonTooltip(true);
    }
  };

  const onMapClicked = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }
    if (showInfoWindowPolygon) {
      for (let i = 0; i < polygonRefs.current.length; i++) {
        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }
  };

  const onInfoWindowClose = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
      setActiveMarker({});
    }

    if (showInfoWindowPolygon) {
      for (let i = 0; i < polygonRefs.current.length; i++) {
        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }

      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }

    if (showInfoWindowPolygonTooltip) {
      setShowInfoWindowPolygonTooltip(false);
      setActiveMarker({});
    }

    // console.log('se cerró');
  };

  const _onZoomChanged = (e) => {
    // console.log(e, MapRef.current.map.zoom);
    setInitialZoom(MapRef.current.map.zoom);
  };

  const _onCenterChanged = (mapProps, map) => {
    // console.log(mapProps, '...', map);
    // console.log(map.center.lat());
    // console.log(map.center.lng());
    // setInitialZoom(MapRef.current.map.zoom);
    // console.log("-------setMapInitialLocation1")
    setMapInitialLocation({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  useEffect(() => {
    if (orchard_id_filter) {
      const result = userOrchard_filter.filter(
        (ele) => ele.id === orchard_id_filter
      );
      // console.log(
      //   result,
      //   '----------------------------result orchard para actualizar mapa'
      // );

      console.log(
        result[0],
        '--------------huerto en mapa',
        orchard_id_filter,
        specie_filter
      );

      const validPolygonOrchard = result[0].polygon
        ? getValidPolygonsById(result[0], specie_filter)
        : null;

      const validPlant1 = result[0].plant1
        ? getValidPlant1ById(result[0])
        : null;

      console.log(
        validPolygonOrchard,
        '-----------------------------------validPoygons',
        validPlant1
      );

      setQuartersState(validPolygonOrchard);

      setPlant1State(validPlant1);

      setInitialZoom(15);
      // console.log("setMapInitialLocation2")
      // setMapInitialLocation(centerOrchard);
    }
  }, [orchard_id_filter]);

  const closeToolTip = () => {
    setShowInfoWindowPolygonTooltip(false);
  };

  const validatePolygonIrrigation = (quarter) => {
    // console.log(polygon, '-----------------polygon');
    if (quarter) {
      return irrigationData.some(
        (item) => item.idUnitProductive === quarter.idUnitProductive
      );
    }
  };

  const getPolygonFillColor = (data) => {
    if (data) {
      const SWC60 = data.SWC60;

      if (SWC60 < 45) {
        return {
          fillColor: 'rgba(219, 135, 24, 0.8)',
          strokeColor: '#db8718',
        }; // orange
      } else if (SWC60 >= 45 && SWC60 < 70) {
        return {
          fillColor: 'rgba(0, 128, 0, 0.8)',
          strokeColor: '#008000',
        }; // green
      } else if (SWC60 >= 70) {
        return {
          fillColor: 'rgba(0, 0, 255, 0.8)',
          strokeColor: '#0000ff',
        }; // blue
      }
      return '#f9fc1c'; // yellow (default)
    }
  };

  // crear función que recibe quartersState y devuelve los polígonos de idUnitProductive que existen en irrigationData
  const getValidIrrigationPolygons = (quartersState) => {
    return quartersState.filter((quarter) =>
      irrigationData.some(
        (item) => item.idUnitProductive === quarter.idUnitProductive
      )
    );
  };

  const polys =
    quartersState &&
    irrigationData &&
    getValidIrrigationPolygons(quartersState).map((quarter, index) => {
      // console.log(
      //   quarter.idUnitProductive,
      //   '----------------quartersState en MAPA'
      // );

      // console.log('dibuja los polys', validatePolygonIrrigation(quarter));
      if (
        validatePolygonIrrigation(quarter) &&
        quarter.geom.coordinates.length
      ) {
        // uniqRender.current = true;

        const dataPolygonIrrigation = irrigationData.find(
          (item) => item.idUnitProductive === quarter.idUnitProductive
        );

        // console.log(
        //   dataPolygonIrrigation,
        //   '-----------------dataPolygonIrrigation',
        //   getPolygonFillColor(dataPolygonIrrigation)
        // );

        return (
          <Polygon
            key={`${quarter.centro_costo}_${index}`}
            ref={(ref) => {
              if (ref && polygonRefs.current.length < quartersState.length) {
                polygonRefs.current[index] = ref;
              }
            }}
            paths={getCoordsByGeom(quarter.geom)}
            onClick={(props, marker, e) =>
              onPolygonClick(props, marker, e, quarter)
            }
            onMouseover={(props, marker, e) =>
              !activeQuarterDataState && quarter.geom.type !== 'MultiPolygon'
                ? onPolygonOver(props, marker, e, quarter)
                : {}
            }
            // id={`${quarter.orchard}_${quarter.cc}_${quarter.quarter}`}
            id={`${quarter.idUnitProductive}`}
            onMouseout={closeToolTip}
            strokeColor={getPolygonFillColor(dataPolygonIrrigation).strokeColor}
            strokeOpacity={1}
            strokeWeight={2}
            // fillColor={quarter.color}
            fillOpacity={1}
            options={{
              fillColor: getPolygonFillColor(dataPolygonIrrigation).fillColor,
            }}
          />
        );
      } else {
        return null;
      }
    });

  const _mapLoaded = (mapProps, map) => {
    const { google } = mapProps;
    setMapState(map);
    map.setOptions({
      mapTypeId: 'hybrid',
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      fullscreenControl: !library,
      disableDefaultUI: !!library,
    });
  };

  useEffect(() => {
    console.log(buttonClick, '<----------buttonClick--------');
    const result = userOrchard_filter.filter(
      (ele) => ele.value === orchard_filter
    );

    const centerOrchardMark = result[0]?.location;

    if (result && result.length) {
      const bounds = new props.google.maps.LatLngBounds();

      const centerOrchardByPolygon = result[0]?.polygon;

      if (centerOrchardByPolygon && centerOrchardByPolygon.length) {
        console.log('ENTRA EN 1  PARA CENTRAR');

        let arrayCoords = [];

        centerOrchardByPolygon.forEach((polygon) => {
          arrayCoords.push(getCoordsByGeom(polygon.geom));
        });

        const flattenArray = _.flatten(arrayCoords);

        const flattenedArray = flattenArray.reduce(
          (acc, val) => acc.concat(val),
          []
        );

        console.log(
          flattenedArray,
          '-----------------flattenedArray DE POLYGONS'
        );

        flattenedArray.forEach((point) => {
          bounds.extend(new props.google.maps.LatLng(point.lat, point.lng));
        });

        console.log(bounds, '-----------------bounds');

        if (mapState && bounds) {
          mapState.fitBounds(bounds);
          console.log('dentro de map', bounds.getCenter());
        }
      } else if (
        centerOrchardMark &&
        centerOrchardMark.lat &&
        centerOrchardMark.lng
      ) {
        console.log('ENTRA EN 2  PARA CENTRAR');

        setMapInitialLocation(centerOrchardMark);
        setInitialZoom(15);
      } else {
        console.log('no tiene nada');
        console.log('ENTRA EN 3 PARA CENTRAR');
      }
    }
  }, [buttonClick, mapState]);

  // console.log(orchard_filter, orchard_id_filter, '<<<<<<<<<');

  const onInfoWindowOpen = (props, e) => {
    // console.log(props, e, '<-------');
    let button;
    if (library) {
      button = (
        <>
          <button
            type='button'
            className={clsx('btn btn-primary btn-sm', classes.irButton)}
            onClick={libraryFilter}
          >
            Filtrar
          </button>
        </>
      );
    } else {
      button = (
        <>
          <button
            type='button'
            className={clsx('btn btn-primary btn-sm', classes.irButton)}
            onClick={() => history.push('/library')}
          >
            Ir a Biblioteca
          </button>
        </>
      );
    }
    // ReactDOM.render(
    // 	React.Children.only(button),
    // 	document.getElementById('iwc')
    // );
    let container = null;
    container = document.getElementById('iwc');
    const root = createRoot(container);

    root.render(React.Children.only(button));
  };

  useEffect(() => {
    // como hay un cuarter seleccionado desde el panel, lo pinta en el mapa
    // Ahora lo hace por el idUnitProductive
    if (quarterSelected) {
      if (showInfoWindow) {
        setShowInfoWindow(false);
      }
      if (showInfoWindowPolygon) {
        setShowInfoWindowPolygon(false);
      }

      console.log(
        quarterSelected,
        '<----------------------------quarterSelected',
        quartersState
      );

      const getIdUnitProductives = (obj) => {
        let ids = [];
        if (obj.variety) {
          obj.variety.forEach((item) => {
            if (item.idUnitProductive) {
              ids.push(item.idUnitProductive);
            }
          });
        }
        return ids;
      };

      // console.log(
      //   getIdUnitProductives(quarterSelected.quarter),
      //   '----ids del Cuartel'
      // );

      const idUnitArrayFromQuarter = getIdUnitProductives(
        quarterSelected.quarter
      );

      // console.log(
      //   quartersState,
      //   '----------------------------quartersState en polygon color',
      //   idUnitArrayFromQuarter,
      //   quarterSelected
      // );

      // const plant1Quarter =
      //   plant1State && plant1State.length
      //     ? plant1State.filter((item) =>
      //         idUnitArrayFromQuarter.includes(item.idUnitProductive)
      //       )
      //     : [];

      // console.log(plant1Quarter, '----------------firstPlant1', plant1State);

      // setPlant1QuarterState(plant1Quarter);

      // find in quartersState the idUnitProductive
      const findFirstIdUnitProductive = (data, target) => {
        const targetId = target.variety[0].idUnitProductive;
        return data.find((item) => item.idUnitProductive === targetId) || null;
      };

      // TODO: falta seleccionar el cuartel en el mapa
      setActiveQuarterDataState(
        findFirstIdUnitProductive(quartersState, quarterSelected.quarter)
      );
    }
  }, [quarterSelected]);

  useEffect(() => {
    if (activeQuarterDataState) {
      console.log(
        activeQuarterDataState,
        '----------------activeQuarterDataState',
        activeQuarterDataState.idUnitProductive
      );

      for (let i = 0; i < polygonRefs.current.length; i++) {
        console.log(polygonRefs.current[i], '-----POLYGONS');

        polygonRefs.current[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
        if (
          polygonRefs.current[i].props.id ===
          activeQuarterDataState.idUnitProductive
        ) {
          // cambiar el fillColor
          polygonRefs.current[i].polygon.setOptions({
            fillColor: polygonFillColorState[1],
          });
        }
      }
    }
  }, [activeQuarterDataState, polys]);

  // console.log(activeQuarterDataState, '-------activeQuarterDataState');

  return (
    <div
      style={{
        // backgroundColor: 'red',
        // height: 'calc(100vh - 64px)',
        // height: '100vh',
        // width: '100vw',
        // position: 'fixed',
        // top: '64px',
        // left: '64px',
        width: '100%',
        height: '100%',
      }}
    >
      <Map
        style={style}
        google={props.google}
        onClick={onMapClicked}
        onZoomChanged={_onZoomChanged}
        onDragend={_onCenterChanged}
        // onCenterChanged={_onCenterChanged}
        initialCenter={mapInitialLocation}
        center={mapInitialLocation}
        zoom={initialZoom}
        ref={MapRef}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      >
        {
          // debe marcar mis orchards
          // marca solo los orchardStatus = true
          userOrchard_filter
            .filter((ele) => ele[moduleOrchardFilter] === true)
            .map((marker) => {
              if (marker.location) {
                return (
                  <Marker
                    key={marker._id}
                    name={marker.label}
                    onClick={
                      mapState && mapState.zoom < 14
                        ? () => onMarkerClick(marker)
                        : (props, marker) => onMarkerClickZoom(props, marker)
                    }
                    onMouseover={
                      mapState && mapState.zoom < 14 ? onMarkerOver : null
                    }
                    position={marker.location}
                  />
                );
              } else {
                return null;
              }
            })
        }
        {/* {plant1QuarterState &&
          plant1QuarterState.length &&
          plant1QuarterState.map((marker) => {
            if (marker.geom) {
              return (
                <Marker
                  key={marker.idUnitProductive}
                  name={marker.idUnitProductive}
                  position={getCoords([marker.geom.coordinates])[0]}
                  icon={IconTree}
                />
              );
            } else {
              return null;
            }
          })} */}
        <InfoWindow
          marker={activeMarker}
          visible={showInfoWindow}
          onClose={onInfoWindowClose}
          // style={styles.infoWindow}
        >
          <div className={classes.infoOrchardStyle}>
            <h5>{selectedPlace.name}</h5>
          </div>
        </InfoWindow>

        {activeQuarterDataState && library && (
          <InfoWindow
            position={activePolygonState}
            visible={showInfoWindowPolygon}
            onClose={onInfoWindowClose}
            onOpen={(e) => {
              onInfoWindowOpen(props, e);
            }}
          >
            <div className={classes.infoCuarterStyle}>
              <table className='table'>
                <tr>
                  <td>Cliente</td>
                  <td>{dashDelete(activeQuarterDataState?.client)}</td>
                </tr>
                <tr>
                  <td>Centro de Costo</td>
                  <td>{activeQuarterDataState?.cc}</td>
                </tr>
                <tr>
                  <td>Cuartel</td>
                  <td>{activeQuarterDataState?.quarter}</td>
                </tr>
                {/* <tr>
                  <td>Especie</td>
                  <td>{activeQuarterDataState?.especie}</td>
                </tr> */}
                <tr>
                  <td>Variedad</td>
                  <td>{activeQuarterDataState?.variety}</td>
                </tr>
                <tr>
                  <td>Portainjerto</td>
                  <td>{activeQuarterDataState?.rootStock}</td>
                </tr>
                {/* <tr>
									<td colSpan={2} className="text-center">
										<a
											className={clsx(
												'btn',
												classes.irButton
											)}
											href="/library"
											role="button"
										>
											Ir a la biblioteca
										</a>
									</td>
								</tr> */}
                <tr>
                  <td colSpan={2} className='text-center'>
                    <div id='iwc'></div>
                  </td>
                </tr>
              </table>
            </div>
          </InfoWindow>
        )}

        {/* TOOLTIP */}
        {activeQuarterTooltipState && !library && (
          <InfoWindow
            position={activePolygonTooltipState}
            visible={showInfoWindowPolygonTooltip}
          >
            <div className={classes.infoCuarterStyle}>
              <p>
                <b>Cuartel:</b> {dashDelete(activeQuarterTooltipState.quarter)}
              </p>
              <p>
                <b>Variedad:</b> {dashDelete(activeQuarterTooltipState.variety)}
              </p>
            </div>
          </InfoWindow>
        )}

        {polys}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  language: 'es',
  // apiKey: 'AIzaSyCgDjp2D44L-NQ5okVOSlCXmBwtkScGmLM',
  apiKey: 'AIzaSyBnXQDrdvbXPg0GjUdQzC_-v0ZMa3q5U9k',
  libraries: ['visualization'],
})(MapComponent);
